@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #18A6C5;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l5 1s infinite;
}

.loader.small {
  width: 30px;
  --b: 5px
}

.loader.extra-small {
  width: 20px;
  --b: 3px
}

@keyframes l5 {
  to {
    transform: rotate(.5turn)
  }
}

a,
button {
  @apply cursor-pointer;
}

.rr-button-gradient {
  background: rgb(54, 197, 254);
  background: linear-gradient(90deg, rgba(54, 197, 254, 1) 0%, rgba(33, 147, 254, 1) 100%);
}

.rr-text-gradient {
  background-image: linear-gradient(90deg, rgba(54, 197, 254, 1) 0%, rgba(33, 147, 254, 1) 100%);
  color: transparent;
  background-clip: text;
}

.rr-drop-gradient {
  background: rgb(54, 197, 254);
  background: linear-gradient(160deg, rgba(54, 197, 254, 0.2) 0%, rgba(255, 255, 255, 0.05) 45%);
}

.react-daterange-picker__calendar .react-daterange-picker__calendar--open {
  @apply z-10;
}

.react-calendar {
  background: #1D2839 !important;
  color: white;
}

.no-result-datepicker {
  height: 72px;
}

.result-datepicker {
  height: 56px;
}

.dash-datepicker {
  height: 56px;
}

.dash-datepicker .react-daterange-picker__wrapper {
  border: inherit;
}

.react-daterange-picker__wrapper {
  background: #1D2839;
  border: none;
  color: #fff;
  @apply rounded-md;
  text-align: center;
  padding: 0 10px;
}

.no-result-datepicker .react-daterange-picker__wrapper {
  @apply !border-none;
}

.result-datepicker .react-daterange-picker__wrapper {
  @apply !border-none;
}

.dash-datepicker .react-daterange-picker__wrapper {
  @apply !border !border-[#2e425f];
  @apply bg-r-gray-medium;
  @apply !h-fit;
  @apply !p-[5px];
}

.react-daterange-picker__button svg {
  stroke: white;
}

.react-daterange-picker__button:hover svg {
  @apply !stroke-r-blue;
}

.react-calendar__month-view__days__day--weekend {
  color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  @apply !bg-r-blue;
}

.react-calendar__tile--active {
  @apply !bg-r-blue;
}

.react-calendar__tile--now {
  background: dodgerblue !important;
}

.react-calendar__tile--hover {
  @apply !bg-r-blue;
}

.react-calendar__tile:disabled {
  @apply !bg-r-gray-dark;
}

.react-calendar__navigation button:disabled {
  @apply !bg-r-gray-dark;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply !bg-r-blue;
}